import React, { useEffect } from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Script } from "gatsby"
import { smoothScrollScript } from "../utils/external-scripts"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 60 * 60 * 1000, // 1h
      cacheTime: 4 * 60 * 60 * 1000, // 24 godziny
    },
  },
})

const RootElement = ({ children }) => {
  // Add useEffect to handle Cookiebot consent events
  useEffect(() => {
    // Function to handle consent changes
    function handleCookieConsentChange(consentData) {
      if (consentData.detail) {
        const { marketing, statistics, preferences } = consentData.detail

        // Update Google Analytics consent based on user choices
        if (window.gtag) {
          window.gtag("consent", "update", {
            ad_storage: marketing ? "granted" : "denied",
            analytics_storage: statistics ? "granted" : "denied",
            personalization_storage: preferences ? "granted" : "denied",
            ad_personalization: marketing ? "granted" : "denied",
            ad_user_data: marketing ? "granted" : "denied",
            functionality_storage: preferences ? "granted" : "denied",
          })
        }

        // Load other scripts only if marketing consent is given
        if (marketing) {
          // Load Microsoft Bing Ads
          if (!window.uetq) {
            loadBingAds()
          }

          // Load TikTok Pixel
          if (!window.ttq) {
            loadTikTokPixel()
          }

          // Load Onet Ads
          if (!window.dlApiLoaded) {
            loadOnetAds()
          }
          // Load WP Pixel
          if (!window.wph) {
            loadWPPixel()
          }

          // Uncomment to load Hotjar if needed
          // if (!window.hj) {
          //   loadHotjar();
          // }
        }
      }
    }

    // Helper functions to load scripts after consent
    function loadBingAds() {
      const script = document.createElement("script")
      script.async = true
      script.src = "//bat.bing.com/bat.js"
      script.onload = function () {
        if (window.UET) {
          window.uetq = new UET({
            ti: "97081979",
            enableAutoSpaTracking: true,
          })
          window.uetq.push("pageLoad")
        }
      }
      document.head.appendChild(script)
    }

    function loadTikTokPixel() {
      const script = document.createElement("script")
      script.async = true
      script.src =
        "https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=CQRTS5BC77U3LH4LN9E0&lib=ttq"
      script.onload = function () {
        if (window.ttq) {
          window.ttq.page()
        }
      }
      document.head.appendChild(script)
    }

    function loadOnetAds() {
      window.dlApi = { cmd: [] }
      const script = document.createElement("script")
      script.async = true
      script.src = "https://lib.onet.pl/s.csr/build/dlApi/minit.boot.min.js"
      document.head.appendChild(script)
      window.dlApiLoaded = true
    }

    function loadWPPixel() {
      !(function (w, p, e, v, n, t, s) {
        w["WphTrackObject"] = n
        ;(w[n] =
          window[n] ||
          function () {
            ;(w[n].queue = w[n].queue || []).push(arguments)
          }),
          (w[n].l = 1 * new Date()),
          (t = p.createElement(e)),
          (s = p.getElementsByTagName(e)[0]),
          (t.async = 1)
        t.src = v
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        "script",
        "https://pixel.wp.pl/w/WP-ADS-J16QB-RXW/tr.js",
        "wph",
      )
      wph("init", "WP-ADS-J16QB-RXW")
    }

    // function loadHotjar() {
    //   (function(h,o,t,j,a,r){
    //     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    //     h._hjSettings={hjid:5067723,hjsv:6};
    //     a=o.getElementsByTagName('head')[0];
    //     r=o.createElement('script');r.async=1;
    //     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    //     a.appendChild(r);
    //   })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    // }

    // Listen for Cookiebot consent events
    window.addEventListener("CookiebotOnAccept", handleCookieConsentChange)
    window.addEventListener("CookiebotOnDecline", handleCookieConsentChange)
    window.addEventListener(
      "CookiebotOnConsentReady",
      handleCookieConsentChange,
    )

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("CookiebotOnAccept", handleCookieConsentChange)
      window.removeEventListener(
        "CookiebotOnDecline",
        handleCookieConsentChange,
      )
      window.removeEventListener(
        "CookiebotOnConsentReady",
        handleCookieConsentChange,
      )
    }
  }, [])

  return (
    <>
      {/* Smooth scrolling script */}
      {/* <Script
        key="smooth-scroll"
        id="smooth-scroll"
        dangerouslySetInnerHTML={{
          __html: smoothScrollScript,
        }}
        defer
      /> */}

      {/* Cookiebot script - essential for consent management */}
      <Script
        key={"gatsby-cookie-consent-mode-cookiebot"}
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="1d920cb8-d500-4561-b66a-20493fe63aff"
        data-blockingmode="auto"
        type="text/javascript"
      />

      {/* Google Analytics base script - will respect consent settings */}
      <Script
        async
        defer
        strategy="idle"
        src="https://www.googletagmanager.com/gtag/js?id=G-6TNEN12MN6"
      />

      {/* Google Analytics default consent configuration - sets everything to denied by default */}
      <Script
        key={"setup-gcm"}
        data-cookieconsent="ignore"
        defer
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default',
          {
          'ad_storage': 'denied',
          'analytics_storage': 'denied',
          'ad_personalization': 'denied',
          'ad_user_data': 'denied',
          'personalization_storage': 'denied',
          'security_storage': 'granted',
          'functionality_storage': 'denied',
          'wait_for_update': 500,
          });
          gtag("set", "ads_data_redaction", true);
          gtag("set", "url_passthrough", false);
        `,
        }}
      />

      {/* Google Analytics configuration - will only track after consent */}
      <Script
        key={"setup-g"}
        strategy="idle"
        defer
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-6TNEN12MN6', {'allow_enhanced_conversions':true});
        `,
        }}
      />

      {/* The other tracking scripts are now handled by the useEffect hook and will only load after consent */}

      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </>
  )
}

export default RootElement
