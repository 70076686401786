import React from "react"
import RootElement from "./src/components/root-element"
// Import global styles first
import "./src/styles.scss"
// Then import vendor styles
import "./src/styles/vendor-styles.css"

// Wrap the root element to ensure proper client-side rendering
export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
